import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { COLORS } from "../../styles"
import { PagingDotsProps } from "nuka-carousel"
import { fill } from "lodash"

const Dots = styled.div`
  display: flex;
  justify-content: center;
`
const Dot = styled.svg<{ isActive: boolean }>`
  fill: ${props =>
    props.isActive ? COLORS.BOTTLE_GREEN : COLORS.LIGHT_BLUE_GREY};
  width: 6px;
  height: 6px;
  margin: 0 3px;
`

export const PagingDots: FunctionComponent<PagingDotsProps> = props => (
  <Dots>
    {fill(Array(props.slideCount), 1).map((_, index) => (
      <Dot isActive={index === Math.round(props.currentSlide)} key={index}>
        <circle cx="3" cy="3" r="3" />
      </Dot>
    ))}
  </Dots>
)
